/* Navbar Top */
.navbar-top {
  background: linear-gradient(to right, white, 75%, #005935);
  /* background-color: #bbf9dd; */
  border-bottom-style: solid;
  border-bottom-color: #006940;
}

.navbar-brand {
  color: #006940;
  margin-left: 30px;
}
.navbar-brand:hover {
  color: #006940;
  margin-left: 30px;
}

.navbar-top .nav-link {
  font-weight: 500;
  color: rgb(218, 218, 218);
}
.navbar-top .nav-link:hover {
  color: silver;
  text-decoration: underline;
}
.navbar-top .user-label {
  color: #006940;
}
.navbar-top .user-label:hover {
  color: #47aa83;
}

/* navbar left */
.navbar-left {
  background-color: #005935;
  background: linear-gradient(to bottom left, #005935, 95%, white);
}

.navbar-left-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.navbar-left .nav-label {
  color: silver;
  font-weight: bold;
  text-decoration: underline;
}

.navbar-left .nav-link {
  color: white;
}

.navbar-left .nav-link.active {
  color: lightgreen;
  /* color: #a7e8c2; */
}

.navbar-left .nav-link:hover {
  color: lightgreen;
  /* color: #daf2e4; */
  /* font-weight: bold; */
  text-decoration: underline;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .navbar-left-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

[role="main"] {
  padding-top: 48px; /* Space for fixed navbar */
  background-color: #e6efeb;
  /* background-color: #eaf2ef; */
}

.form-control {
  background-color: #f3f7f5;
}

.btn-group-toggle.form-control {
  background-color: #e6efeb;
}

.clickable {
  cursor: pointer;
}

.btn-group-toggle.form-control {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
}

.radio-button {
  height: 2rem;
  width: 9rem;
}
.radio-button-sm {
  width: 8rem;
}
